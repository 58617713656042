<template>
  <div class="app-container calendar-list-container">
    <basic-container>
      <avue-crud
          ref="crud"
          @on-load="getPage"
          :option="tableOption"
          :data="tableData"
          :permission="permissionList"
          :table-loading="tableLoading"
          :before-open="beforeOpen"
          v-model="form"
          @refresh-change="refreshChange"
          @row-update="handleUpdate"
          @row-save="handleSave"
          @row-del="handleDel">
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
import {addObj, delObj, fetchTree, putObj} from '@/api/upms/organ'
import {tableOption} from '@/const/crud/upms/organ'
import {mapGetters} from 'vuex'
import {parseTableOption} from "@/util/util"

export default {
  name: 'organ',
  data() {
    return {
      form: {},
      tableOption: this.getOption(tableOption),
      tableData: [],
      tableLoading: false
    }
  },
  created() {
    /*remote('organ_type').then(response => {
      this.tableOption.column[2].dicData = response.data.data
    })*/
  },
  watch: {
    '$i18n.locale'() {
      this.getOption(tableOption)
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    permissionList() {
      return {
        addBtn: this.permissions['sys:organ:add'] ? true : false,
        delBtn: this.permissions['sys:organ:del'] ? true : false,
        editBtn: this.permissions['sys:organ:edit'] ? true : false,
        viewBtn: this.permissions['sys:organ:get'] ? true : false
      }
    }
  },
  methods: {
    getOption(tableOption) {
      parseTableOption(tableOption, 'prop', (option) => {
        let val = option.prop
        if (val === 'createTime' || val === 'updateTime') {
          option.label = this.$t('curd.' + val)
        } else {
          option.label = this.$t('curd.upms.organ.' + val)
        }
      })
      return tableOption
    },
    getPage() {
      this.tableLoading = true
      fetchTree().then(response => {
        this.tableData = response.data.data
        this.$refs.crud.DIC.parentId = this.tableData
        this.tableLoading = false
      }).catch(() => {
        this.tableLoading = false
      })
    },
    handleDel: function (row) {
      var _this = this
      this.$confirm('是否确认删除此数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        return delObj(row.id)
      }).then(() => {
        _this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
        this.getPage(this.page)
      }).catch(function () {
      })
    },
    /**
     * @title 数据更新
     * @param row 为当前的数据
     * @param index 为当前更新数据的行数
     * @param done 为表单关闭函数
     *
     **/
    handleUpdate: function (row, index, done, loading) {
      putObj(row).then(() => {
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        })
        done()
        this.getPage(this.page)
      }).catch(() => {
        loading()
      })
    },
    /**
     * @title 数据添加
     * @param row 为当前的数据
     * @param done 为表单关闭函数
     *
     **/
    handleSave: function (row, done, loading) {
      addObj(row).then(() => {
        this.$message({
          showClose: true,
          message: '添加成功',
          type: 'success'
        })
        done()
        this.getPage(this.page)
      }).catch(() => {
        loading()
      })
    },
    /**
     * 刷新回调
     */
    refreshChange() {
      this.getPage(this.page)
    },
    beforeOpen(done, type) {
      window.openType = type
      done()
    },
  }
}
</script>

