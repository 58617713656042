import request from '@/router/axios'

export function addObj(obj) {
    return request({
        url: '/version/stb/info',
        method: 'put',
        data: obj
    })
}

export function getPage(query) {
    return request({
        url: '/version/stb/info/page',
        method: 'get',
        params: query
    })
}

export function delObj(id) {
    return request({
        url: '/version/stb/info/' + id,
        method: 'delete'
    })
}

export function putObj(obj) {
    return request({
        url: '/version/stb/info/update',
        method: 'put',
        data: obj
    })
}
