//import upload from "@/components/commom/upload";
import i18n from '../../../lang'
let form = {}
export const tableOption = {
    dialogDrag: true,
    headerAlign: 'center',
    align: 'center',
    border: true,
    editBtn: true,
    delBtn: true,
    viewBtn: true,
    column: [
        {
            label: '节目名称',
            prop: 'name',
            search: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '背景url',
            prop: 'bgImageUrl',
            hide: true,
            overHidden: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '封面url',
            prop: 'cardImageUrl',
            overHidden: true,
            hide: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '节目源',
            type: 'select',
            prop: 'channelInfoId',
            dicUrl: '/iptv/tv/channel/page?pageIndex=0&pageSize=99',
            props: {
                label: 'name',
                value: 'id',
                res: "data.content"
            },
            change: ({dic, value}) => {
                dic.forEach((val) => {
                    if (val.id === value) {
                        form.source = val.source
                    }
                })
            },
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '所属分组',
            type: 'select',
            prop: 'groupId',
            search: true,
            dicUrl: '/iptv/video/group/all/program',
            props: {
                label: 'name',
                value: 'id',
            },
            rules: [{
                required: true,
                message: '请选择所属分组',
                trigger: 'blur'
            }]
        },
        {
            label: '节目号',
            type: 'number',
            prop: 'number',
            rules: [{
                required: true,
                message: '节目号不能为空',
                trigger: 'blur'
            }]
        },
        {
            label: '是否加密',
            prop: 'isEncrypt',
            type: 'radio',
            value: false,
            dicData: [{
                label: i18n.t('curd.dictDataYES'),
                value: true
            }, {
                label: i18n.t('curd.dictDataNO'),
                value: false
            }],
        },
    ]
}