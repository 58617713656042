//import upload from "@/components/commom/upload";
export const tableOption = {
    dialogDrag: true,
    headerAlign: 'center',
    align: 'center',
    border: true,
    editBtn: true,
    delBtn: true,
    viewBtn: true,
    column: [
        {
            label: '分组名称',
            prop: 'name',
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: 'EMM传输流',
            prop: 'emmUrl',
            hide: true,
        },
        {
            label: '所属菜单',
            prop: 'tvMenuId',
            type: 'select',
            search: true,
            dicUrl: '/iptv/tv/menu/page?pageIndex=0&pageSize=99',
            props: {
                label: 'name',
                value: 'id',
                res:"data.content"
            },
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '简介',
            prop: 'description',
            overHidden: true,
            type: 'textarea',
            row: true,
            span: 24,
            rules: [{
                trigger: 'change'
            }],
        },
    ]
}