import i18n from '../../../lang'

export const tableOption = {
    dialogDrag: true,
    headerAlign: 'center',
    align: 'center',
    border: true,
    //excelBtn: true,
    //viewBtn: true,
    column: [
        {
            label: '机顶盒号',
            prop: 'stbNo',
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '芯片厂商',
            prop: 'chipMakers',
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '芯片型号',
            prop: 'chipModel',
            search: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '机顶盒型号',
            prop: 'stbModel',
            search: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '运营商',
            prop: 'operator',
            search: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '内存大小',
            prop: 'stbMemory',
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: 'flash大小',
            prop: 'stbFlash',
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '安卓版本号',
            prop: 'androidVersion',
            rules: [{
                required: true,
                trigger: 'blur'
            }],
        },
        {
            label: '是否生效',
            prop: 'isValid',
            type: "radio",
            value: true,
            search: true,
            dicData: [{
                label: i18n.t('curd.dictDataNO'),
                value: false
            }, {
                label: i18n.t('curd.dictDataYES'),
                value: true
            }]
        }
    ]
}