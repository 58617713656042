import iconList from "@/const/iconList";
import i18n from '../../../lang'

export const tableOption = {
    dialogDrag: true,
    headerAlign: 'center',
    align: 'center',
    border: true,
    viewBtn: true,
    column: [
        {
            label: i18n.t('curd.upms.menu.parentId'),
            prop: 'parentId',
            type: 'tree',
            hide: true,
            props: {
                label: 'name',
                value: 'id'
            },
            rules: [{
                required: true,
                message: i18n.t('curd.selectMsg') + i18n.t('curd.upms.menu.parentId'),
                trigger: 'change'
            }]
        },
        {
            label: i18n.t('curd.upms.menu.name'),
            prop: 'name',
            align: 'left',
            width: 200,
            rules: [{
                required: true,
                message: i18n.t('curd.upms.menu.name') + i18n.t('curd.warning'),
                trigger: 'blur'
            }]
        },
        {
            label: i18n.t('curd.upms.menu.type'),
            prop: 'type',
            type: 'select',
            slot: true,
            dicData: [{
                label: i18n.t('curd.upms.menu.dicDataMenu'),
                value: '0'
            }, {
                label: i18n.t('curd.upms.menu.dicDataButton'),
                value: '1'
            }],
            rules: [{
                required: true,
                message: i18n.t('curd.selectMsg') + i18n.t('curd.upms.menu.code'),
                trigger: 'blur'
            }]
        },
        {
            label: i18n.t('curd.upms.menu.code'),
            prop: 'code',
            align: 'center',
            width: 200,
            rules: [{
                required: true,
                message: i18n.t('curd.upms.menu.code') + i18n.t('curd.warning'),
                trigger: 'blur'
            }]
        },
        {
            label: i18n.t('curd.upms.menu.number'),
            type: 'number',
            prop: 'sort',
            rules: [{
                required: true,
                message: i18n.t('curd.upms.menu.number') + i18n.t('curd.warning'),
                trigger: 'blur'
            }]
        },
        {
            label: i18n.t('curd.upms.menu.permission'),
            prop: 'permission'
        },
        {
            label: i18n.t('curd.upms.menu.icon'),
            prop: 'icon',
            type: 'icon',
            iconList: iconList,
            slot: true,
            display: false
        },
        {
            label: i18n.t('curd.upms.menu.component'),
            prop: 'component',
            display: false
        },
        {
            label: i18n.t('curd.upms.menu.path'),
            prop: 'path',
            display: false
        },
        {
            label: i18n.t('curd.upms.menu.roleId'),
            prop: 'roleId',
            type: 'select',
            hide: true,
            sortable: true,
            //dicData:[],
            dicUrl: '/upms/role/list',
            props: {
                label: 'roleName',
                value: 'id'
            },
            rules: [{
                required: true,
                message: i18n.t('curd.selectMsg') + i18n.t('curd.upms.menu.roleId'),
                trigger: 'change'
            }],
            editDisplay: false,
            viewDisplay: false
        },
        {
            label: i18n.t('curd.createTime'),
            prop: 'createTime',
            hide: true,
            editDisplay: false,
            addDisplay: false
        },
        {
            label: i18n.t('curd.updateTime'),
            prop: 'updateTime',
            hide: true,
            editDisplay: false,
            addDisplay: false
        }
    ]
}
