import upload from "@/components/commom/upload";
export const tableOption = {
    dialogDrag: true,
    headerAlign: 'center',
    align: 'center',
    border: true,
    editBtn: true,
    delBtn: true,
    viewBtn: true,
    column: [
        {
            label: '频道名称',
            prop: 'name',
            search: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        /*{
            label: '频道logo',
            prop: 'logo',
            hide: true,
            overHidden: true,
        },*/
        {
            label: '频道源地址',
            prop: 'source',
            overHidden: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        /*{
            label: 'logo上传',
            prop: 'logoUpload',
            type: 'upload',
            listType: 'picture-card',
            row: true,
            hide: true,
            viewDisplay: false,
            action:"/iptv/tv/channel/upload/logo"
        }*/
    ]
}