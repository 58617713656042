//import upload from "@/components/commom/upload";
export const tableOption = {
    dialogDrag: true,
    headerAlign: 'center',
    align: 'center',
    border: true,
    editBtn: true,
    delBtn: true,
    viewBtn: true,
    column: [
        {
            label: '菜单名称',
            prop: 'name',
            search: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '菜单图标',
            prop: 'icon',
            hide: true,
            overHidden: true
        },
        {
            label: '菜单排序',
            prop: 'sort',
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '简介',
            prop: 'description',
            overHidden: true,
            type: 'textarea',
            row: true,
            span: 24,
            rules: [{
                trigger: 'change'
            }],
        },
    ]
}