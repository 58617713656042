export const tableOption = {
    dialogDrag: true,
    headerAlign: 'center',
    align: 'center',
    border: true,
    //viewBtn: true,
    column: [
        {
            label: '芯片厂商',
            prop: 'chipMakers',
            rules: [{
                required: true,
                message: '芯片厂商',
                trigger: 'blur'
            }]
        },
        {
            label: '芯片型号',
            prop: 'chipModel',
            search: true,
            rules: [{
                required: true,
                message: '芯片型号',
                trigger: 'blur'
            }]
        },
        {
            label: '机顶盒型号',
            prop: 'stbModel',
            search: true,
            rules: [{
                required: true,
                message: '机顶盒型号',
                trigger: 'blur'
            }]
        },
        {
            label: '运营商',
            prop: 'operator',
            search: true,
            rules: [{
                required: true,
                message: '运营商',
                trigger: 'blur'
            }]
        },
        {
            label: '内存大小',
            prop: 'stbMemory',
            rules: [{
                required: true,
                message: '内存大小',
                trigger: 'blur'
            }]
        },
        {
            label: 'flash大小',
            prop: 'stbFlash',
            rules: [{
                required: true,
                message: 'flash大小',
                trigger: 'blur'
            }]
        },
        {
            label: '安卓版本号',
            prop: 'androidVersion',
            rules: [{
                required: true,
                message: '安卓版本号',
                trigger: 'blur'
            }],
        }
    ]
}