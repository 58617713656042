import request from '@/router/axios'

export function addObj(obj) {
    return request({
        url: '/version/stb/base',
        method: 'put',
        data: obj
    })
}
export function getPage(query) {
    return request({
        url: '/version/stb/base/page',
        method: 'get',
        params: query
    })
}
export function delObj(id) {
    return request({
        url: '/version/stb/base/' + id,
        method: 'delete'
    })
}
export function putObj(obj) {
    return request({
        url: '/version/stb/base/update',
        method: 'put',
        data: obj
    })
}