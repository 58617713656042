<template>
  <div class="version-app">
    <basic-container>
      <avue-crud
          ref="crud"
          v-model="form"
          :page="page"
          @on-load="getPage"
          :option="tableOption"
          :data="tableData"
          :table-loading="tableLoading"
          @refresh-change="refreshChange"
          @search-change="searchChange"
          @row-del="handleDel">
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {tableOption} from "@/const/crud/upms/log";
import {delObj, getPage} from "@/api/upms/log";
import {parseTableOption} from "@/util/util"
export default {
  name: "version-app",
  data() {
    return {
      form: {},
      tableOption: this.getOption(tableOption),
      tableData: [],
      tableLoading: false,
      page: {
        params: {},
        total: 0, // 总页数
        pageSize: 10 // 每页显示多少条
      },
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    /*permissionList() {
      return {
        addBtn: this.permissions['sys:user:add'] ? true : false,
        delBtn: this.permissions['sys:user:del'] ? true : false,
        editBtn: this.permissions['sys:user:edit'] ? true : false,
        viewBtn: this.permissions['sys:user:get'] ? true : false
      }
    },*/
  },
  watch: {
    '$i18n.locale'() {
      this.getOption(tableOption)
    }
  },
  created() {

  },
  methods: {
    getOption(tableOption) {
      parseTableOption(tableOption, 'prop', (option) => {
        let val = option.prop
        if (val === 'createTime' || val === 'updateTime') {
          option.label = this.$t('curd.' + val)
        } else {
          option.label = this.$t('curd.upms.sysLog.' + val)
        }
      })
      return tableOption
    },
    getPage(page) {
      this.tableLoading = true
      getPage(Object.assign({
        pageIndex: page.currentPage - 1,
        pageSize: page.pageSize,
      }, this.page.params)).then(response => {
        this.tableData = response.data.data.content
        this.page.total = response.data.data.totalElements
        this.page.currentPage = page.currentPage
        this.page.pageSize = page.pageSize
        this.tableLoading = false
      }).catch(() => {
        this.tableLoading = false
      })
    },
    /**
     * 刷新回调
     */
    refreshChange() {
      this.getPage(this.page)
    },
    searchChange(form, done) {
      this.page.currentPage = 1
      this.page.params = this.filterForm(form)
      this.getPage(this.page)
      done()
    },
    handleDel: function (row) {
      const _this = this;
      this.$confirm('是否确认删除?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        return delObj(row.id)
      }).then(() => {
        this.tableData = []
        this.getPage(this.page)
        _this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
      }).catch(function () {
      })
    },
  }
};
</script>
<style lang="scss" scoped>

</style>

