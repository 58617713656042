import {getByUserName, getCount} from '@/api/upms/user'
import i18n from '../../../lang'

const validateUserName = (rule, value, callback) => {
    if (window.openType === 'edit'){
        callback()
    }else{
        getByUserName(value).then(response => {
            let data = response.data.data
            if (data !== null) {
                callback(new Error(i18n.t('curd.upms.user.accountErrorMsg')))
            } else {
                callback()
            }
        })
    }
}

const validateEmail = (rule, value, callback) => {
    if (window.openType === 'edit'){
        callback()
    }else{
        getCount({
            email: value
        }).then(response => {
            let data = response.data.data
            if (data > 0) {
                callback(new Error(i18n.t('curd.upms.user.accountErrorMsg')))
            } else {
                callback()
            }
        })
    }
}

const validatePhone = (rule, value, callback) => {
    if (window.openType === 'edit'){
        callback()
    }else{
        getCount({
            phone: value
        }).then(response => {
            let data = response.data.data
            if (data > 0) {
                callback(new Error(i18n.t('curd.upms.user.accountErrorMsg')))
            } else {
                callback()
            }
        })
    }
}

export const tableOption = {
    dialogDrag: true,
    border: true,
    index: true,
    indexLabel: '序号',
    stripe: true,
    menuAlign: 'center',
    align: 'center',
    viewBtn: true,
    excelBtn: true,
    printBtn: true,
    searchMenuSpan: 6,
    column: [{
        fixed: true,
        label: 'id',
        prop: 'id',
        span: 24,
        hide: true,
        editDisplay: false,
        addDisplay: false
    }, {
        fixed: true,
        label: '账号',
        prop: 'username',
        search: true,
        span: 24,
        rules: [{
            message: i18n.t('curd.selectMsg') + i18n.t('curd.upms.user.username'),
            required: true,
        },
            {
                min: 3,
                max: 20,
                message: i18n.t('curd.upms.user.usernameRequiredMsg'),
                trigger: 'blur'
            },{
                validator: validateUserName,
                trigger: 'blur'
            }]
    }, {
        label: '密码',
        prop: 'password',
        type: 'password',
        value: '',
        hide: true,
        viewDisplay: false,
        editDisplay: false,
        span: 24,
        rules: [{
            required: true,
            min: 6,
            max: 20,
            message: i18n.t('curd.upms.user.passwordRequiredMsg'),
            trigger: 'blur'
        }]
    }, {
        label: '所属机构',
        prop: 'organId',
        span: 24,
        sortable: true,
        rules: [{
            required: true,
            message: i18n.t('curd.selectMsg') + i18n.t('curd.upms.user.organId'),
            trigger: 'change'
        }],
        type: 'tree',
        search: true,
        props: {
            label: 'name',
            value: 'id'
        },
        defaultExpandAll: true,
        dicUrl: '/upms/organ/tree'
    }, {
        label: '角色',
        prop: 'roleIds',
        overHidden: true,
        dataType: 'array',
        span: 24,
        type: 'checkbox',
        rules: [{
            required: true,
            message: i18n.t('curd.selectMsg') + i18n.t('curd.upms.user.roleIds'),
            trigger: 'blur'
        }],
        props: {
            label: 'roleName',
            value: 'id'
        },
        dicUrl: '/upms/role/list'
    }, {
        label: '允许登录',
        prop: 'lockFlag',
        type: 'radio',
        slot: true,
        sortable: true,
        span: 24,
        value:0,
        rules: [{
            required: true,
            message: i18n.t('curd.selectMsg') + i18n.t('curd.upms.user.lockFlag'),
            trigger: 'blur'
        }],
        dicData: [{
            label:  i18n.t('curd.dictDataYES'),
            value: '0'
        }, {
            label:  i18n.t('curd.dictDataNO'),
            value: '9'
        }]
    }, {
        width: 180,
        label: '创建时间',
        prop: 'createTime',
        type: 'datetime',
        format: 'yyyy-MM-dd HH:mm',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        editDisabled: true,
        addDisplay: false,
        span: 24
    }, {
        label: '邮箱',
        prop: 'email',
        editDisabled: true,
        rules: [{
            required: true,
            message: i18n.t('curd.selectMsg') + i18n.t('curd.upms.user.email'),
        },{
            validator: validateEmail,
            trigger: 'blur'
        }]
    }, {
        label: '手机号',
        prop: 'phone',
        value: '',
        span: 24,
        editDisabled: true,
        rules: [{
            required: true,
            message: i18n.t('curd.selectMsg') + i18n.t('curd.upms.user.phone'),
        },{
            min: 11,
            max: 11,
            message: i18n.t('curd.upms.user.phoneRequiredMsg'),
            trigger: 'blur'
        },{
            validator: validatePhone,
            trigger: 'blur'
        }]
    }]
}
