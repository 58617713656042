import request from '@/router/axios'

const baseUrl = '/iptv/tv/epg'

export function getPage(query) {
    return request({
        url: baseUrl + '/page',
        method: 'get',
        params: query
    })
}

export function delObj(id) {
    return request({
        url: baseUrl + '/info/' + id,
        method: 'delete'
    })
}

export function putObj(obj) {
    return request({
        url: baseUrl + '/update',
        method: 'put',
        data: obj
    })
}