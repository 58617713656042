import request from '@/router/axios'

const baseUrl = '/iptv/video'

export function addObj(obj) {
    return request({
        url: baseUrl + '/info',
        method: 'put',
        data: obj
    })
}

export function getPage(query) {
    return request({
        url: baseUrl + '/info/page',
        method: 'get',
        params: query
    })
}

export function delObj(id) {
    return request({
        url: baseUrl + '/info/' + id,
        method: 'delete'
    })
}

export function putObj(obj) {
    return request({
        url: baseUrl + '/info/update',
        method: 'put',
        data: obj
    })
}
export function checkFile(param) {
    return request({
        url: baseUrl + '/upload/check',
        method: 'post',
        params: param
    })
}