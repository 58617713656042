<template>
  <div class="zoom" style="overflow:auto">
    <!--eslint-disable-next-line-->
    <div class="media-item" v-for="(resource, index) of resourceInfos">
      <div class="item-div item-div-img" v-if="resource.type===1" style="float: left">
        <img style="width: 100px;height: 100px;margin: 20px;"
             :src="resource.content"
             v-bind:key="index"
             @click="preview(index)"/>
      </div>
      <div class="item-div" v-if="resource.type===2">
        <audio controls="controls"
               type="audio/mpeg"
               :src="resource.content"/>
      </div>
      <div class="item-div" v-if="resource.type===3">
        <video controls="controls"
               width="100%"
               height="250px"
               :src="resource.content" />
      </div>
      <div class="item-div" v-if="resource.type===0">
        <a :href="resource.content" style="color: #037efc">该附件无法预览，请点我下载后查看</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "media-view",
  props: {
    resourceInfos: Array,
  },
  data() {
    return {
      urls: [],
    }
  },
  methods: {
    preview(index) {
      this.urls = []
      for (let i in this.resourceInfos) {
        this.urls.push({
          url: this.resourceInfos[i].content
        })
      }
      this.$ImagePreview(this.urls, index);
    },
  },
}
</script>

<style scoped>
.zoom {
  height: auto !important;
  flex-wrap: wrap;
}
.item-div-img{
  margin-right: 5px;
  border: 1px solid #efeeee;
}
.item-div-img:hover{
  cursor:pointer
}
.item-div{
  margin-bottom: 20px;
  padding: 3px;
}
</style>