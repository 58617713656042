//import upload from "@/components/commom/upload";
import i18n from '../../../lang'
export const tableOption = {
    dialogDrag: true,
    headerAlign: 'center',
    align: 'center',
    border: true,
    editBtn: true,
    delBtn: true,
    viewBtn: true,
    column: [
        {
            label: '视频标题',
            prop: 'title',
            search: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '背景url',
            prop: 'bgImageUrl',
            hide: true,
            overHidden: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '封面url',
            prop: 'cardImageUrl',
            overHidden: true,
            hide: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '播放路径',
            prop: 'url',
            overHidden: true,
        },
        {
            label: 'vip专享',
            prop: 'isVip',
            type: 'radio',
            value: false,
            dicData: [{
                label: i18n.t('curd.dictDataYES'),
                value: true
            }, {
                label: i18n.t('curd.dictDataNO'),
                value: false
            }],
        },
        {
            label: '所属分组',
            type: 'select',
            prop: 'groupId',
            dicUrl: '/iptv/video/group/page?pageIndex=0&pageSize=99',
            props: {
                label: 'name',
                value: 'id',
                res: "data.content"
            },
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '视频路径',
            prop: 'filePath',
            hide: true,
            overHidden: true,
            addDisabled: true,
            editDisabled: true,
        },
        {
            label: '发行年份',
            prop: 'date',
            overHidden: true,
            addDisabled: true,
            editDisabled: true,
        },
        {
            label: '分辨率',
            prop: 'resolution',
            overHidden: true,
            addDisabled: true,
            editDisabled: true,
        },
        {
            label: '视频大小',
            prop: 'size',
            overHidden: true,
            addDisabled: true,
            editDisabled: true,
        }, {
            label: '视频类型',
            prop: 'type',
            search: true,
            overHidden: true,
            addDisabled: true,
            editDisabled: true,
        },
        {
            label: '视频格式',
            prop: 'mediaType',
            search: true,
            overHidden: true,
            addDisabled: true,
            editDisabled: true,
        },
        {
            label: '简介',
            prop: 'description',
            overHidden: true,
            type: 'textarea',
            row: true,
            span: 24,
            rules: [{
                trigger: 'change'
            }],
        },
        {
            label: '视频上传',
            prop: 'sftpUpload',
            formslot: true,
            row: true,
            hide: true,
            viewDisplay: false,
        },
    ]
}