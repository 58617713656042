<template>
  <div>
<!--    <basic-container class="wel-view">
      <h1>首页</h1>
    </basic-container>-->
  </div>
</template>

<script>
  export default {
    name: 'wel',
  }
</script>

<style lang="scss">
  .wel-view {
    height: calc(100% - 30px);
    overflow-y: scroll;
    .el-card__body {
      padding: 5px;
    }
    .data-view-content {
      padding: 10px;
      .change-layout {
        display: flex;
        flex-direction: row;
        .change-item + .change-item {
          border-left: none;
        }
        .change-item {
          padding: 0 10px;
          border: 1px solid #EEEEEE;
          line-height: 30px;
          text-decoration: none;
          outline: none;
          &.active {
            background: #44B549;
            color: #FFFFFF;
          }
        }
      }
    }
  }
</style>
