//import upload from "@/components/commom/upload";
import i18n from '../../../lang'

export const tableOption = {
    dialogDrag: true,
    headerAlign: 'center',
    align: 'center',
    border: true,
    editBtn: false,
    delBtn: true,
    viewBtn: true,
    column: [
        {
            label: '芯片厂商',
            prop: 'chipMakers',
            addDisabled: true,
            editDisabled: true,
            rules: [{
                required: true,
            }]
        },
        {
            label: '芯片型号',
            prop: 'chipModel',
            search: true,
            addDisabled: true,
            editDisabled: true,
            rules: [{
                required: true,
            }]
        },
        {
            label: '机顶盒型号',
            prop: 'stbModel',
            search: true,
            addDisabled: true,
            editDisabled: true,
            rules: [{
                required: true,
            }]
        },
        {
            label: '运营商',
            prop: 'operator',
            addDisabled: true,
            editDisabled: true,
            rules: [{
                required: true,
            }]
        },
        {
            label: '内存大小',
            prop: 'stbMemory',
            addDisabled: true,
            editDisabled: true,
            rules: [{
                required: true,
            }]
        },
        {
            label: 'flash大小',
            prop: 'stbFlash',
            addDisabled: true,
            editDisabled: true,
            rules: [{
                required: true,
            }]
        },


        {
            label: 'md5',
            prop: 'fileMd5',
            hide: true,
            overHidden: true,
            addDisabled: true,
            editDisabled: true,
            rules: [{
                required: true,
            }],
        }, {
            label: '文件大小',
            prop: 'fileSize',
            hide: true,
            overHidden: true,
            addDisabled: true,
            editDisabled: true,
            rules: [{
                required: true,
            }],
        },
        {
            label: '版本号',
            prop: 'version',
            rules: [{
                required: true,
                trigger: 'change'
            }],
        },
        {
            label: '是否强制',
            prop: 'isForce',
            type: "radio",
            value: false,
            dicData: [{
                label: i18n.t('curd.dictDataNO'),
                value: false
            }, {
                label: i18n.t('curd.dictDataYES'),
                value: true
            }]
        }, {
            label: 'minStbNo',
            prop: 'minStbNo',
            addDisplay: false,
            editDisplay: false,
        }, {
            label: 'maxStbNo',
            prop: 'maxStbNo',
            addDisplay: false,
            editDisplay: false,
        }, {
            label: '最小版本',
            prop: 'minVersion',
            search: true,
            addDisplay: false,
            editDisplay: false,
        }, {
            label: '最大版本',
            prop: 'maxVersion',
            search: true,
            addDisplay: false,
            editDisplay: false,
        },
        {
            label: '更新内容',
            prop: 'versionContent',
            type: 'textarea',
            row: true,
            span: 24,
            rules: [{
                required: true,
                trigger: 'change'
            }],
        },
        {
            label: '文件路径',
            prop: 'filePath',
            overHidden: true,
            addDisabled: true,
            editDisabled: true,
            rules: [{
                required: true,
            }],
        }, {
            label: '升级文件',
            prop: 'sftpUpload',
            formslot: true,
            row: true,
            hide: true,
            viewDisplay: false,
        }, {
            label: '状态',
            prop: 'status',
            type: "radio",
            addDisplay: false,
            editDisplay: false,
            viewDisplay: false,
            value: 0,
            dicData: [{
                label: i18n.t('curd.stb.sys.dicDataNotReleased'),
                value: 0
            }, {
                label: i18n.t('curd.stb.sys.dicDataReleased'),
                value: 1
            }],
        },
    ]
}
export const releaseOption = {
    submitText: i18n.t('curd.stb.sys.publishText'),
    column: [{
        label: '最小机顶盒号',
        prop: 'minStbNo',
    }, {
        label: '最大机顶盒号',
        prop: 'maxStbNo'
    }, {
        label: '最小版本',
        prop: 'minVersion',
    }, {
        label: '最大版本',
        prop: 'maxVersion'
    }]
}