import request from '@/router/axios'

const baseUrl = '/iptv/video'

export function addObj(obj) {
    return request({
        url: baseUrl + '/group',
        method: 'put',
        data: obj
    })
}

export function getPage(query) {
    return request({
        url: baseUrl + '/group/page',
        method: 'get',
        params: query
    })
}

export function delObj(id) {
    return request({
        url: baseUrl + '/group/' + id,
        method: 'delete'
    })
}

export function putObj(obj) {
    return request({
        url: baseUrl + '/group/update',
        method: 'put',
        data: obj
    })
}