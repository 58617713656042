//import upload from "@/components/commom/upload";
export const tableOption = {
    dialogDrag: true,
    headerAlign: 'center',
    align: 'center',
    border: true,
    addBtn: false,
    editBtn: true,
    delBtn: true,
    viewBtn: true,
    column: [
        {
            label: '节目名称',
            prop: 'name',
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '播放日期',
            prop: 'date',
            type: 'datetime',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            search: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '开始时间',
            prop: 'startTime',
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            search: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '结束时间',
            prop: 'endTime',
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            search: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '语言',
            prop: 'language',
            editDisplay: false,
        },
        {
            label: '国家',
            prop: 'countryName',
            editDisplay: false,
        },
        {
            label: '评级',
            type: 'number',
            prop: 'rating',
        },
        {
            label: '频道',
            type: 'select',
            prop: 'programInfoId',
            dicUrl: '/iptv/tv/program/page?pageIndex=0&pageSize=999',
            props: {
                label: 'name',
                value: 'id',
                res: "data.content"
            },
            search: true,
            rules: [{
                required: true,
                trigger: 'blur'
            }]
        },
        {
            label: '简介',
            prop: 'description',
            overHidden: true,
            type: 'textarea',
            row: true,
            span: 24,
            rules: [{
                trigger: 'change'
            }],
        },
    ]
}