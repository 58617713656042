<template>
  <div class="version-app">
    <basic-container>
      <avue-crud
          ref="crud"
          v-model="form"
          :page="page"
          @on-load="getPage"
          :option="tableOption"
          :data="tableData"
          :table-loading="tableLoading"
          :row-class-name="handleClassName"
          @refresh-change="refreshChange"
          @search-change="searchChange"
          @row-update="handleUpdate"
          @row-save="handleSave"
          @row-del="handleDel">
        <template slot="sftpUploadForm">
          <sftpUpload
              :upload-url="uploadUrl"
              :check-url="checkUrl"
              :checkMd5="false"
              :beforeUpload="beforeUpload"
              :error-callback="errorUpload"
              :success-callback="successUpload"></sftpUpload>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {tableOption} from "@/const/crud/iptv/video";
import {addObj, checkFile, delObj, getPage, putObj} from "@/api/iptv/video";
import sftpUpload from "@/components/commom/sftp-upload.vue";

import {parseTableOption} from "@/util/util"
export default {
  name: "version-app",
  components: {sftpUpload},
  data() {
    return {
      form: {},
      releaseForm: {},
      tableOption: this.getOption(tableOption),
      tableData: [],
      tableLoading: false,
      uploadUrl: '/iptv/video/upload/chunk',
      checkUrl: '/iptv/video/upload/progress',
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10 // 每页显示多少条
      },
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    /*permissionList() {
      return {
        addBtn: this.permissions['sys:user:add'] ? true : false,
        delBtn: this.permissions['sys:user:del'] ? true : false,
        editBtn: this.permissions['sys:user:edit'] ? true : false,
        viewBtn: this.permissions['sys:user:get'] ? true : false
      }
    },*/
  },
  watch: {
    '$i18n.locale'() {
      this.getOption(tableOption)
    }
  },
  created() {

  },
  methods: {
    getOption(tableOption) {
      parseTableOption(tableOption, 'prop', (option) => {
        let val = option.prop
        if (val === 'createTime' || val === 'updateTime') {
          option.label = this.$t('curd.' + val)
        } else {
          option.label = this.$t('curd.iptv.video.' + val)
        }
      })
      return tableOption
    },
    getPage(page) {
      this.tableLoading = true
      getPage(Object.assign({
        pageIndex: page.currentPage - 1,
        pageSize: page.pageSize,
      }, this.page.params)).then(response => {
        this.tableData = response.data.data.content
        this.page.total = response.data.data.totalElements
        this.page.currentPage = page.currentPage
        this.page.pageSize = page.pageSize
        this.tableLoading = false
      }).catch((error) => {
        this.tableLoading = false
        this.$message({
          showClose: true,
          message: error,
          type: 'error'
        })
      })
    },
    /**
     * 刷新回调
     */
    refreshChange() {
      this.getPage(this.page)
    },
    searchChange(form, done) {
      this.page.currentPage = 1
      this.page.params = this.filterForm(form)
      this.getPage(this.page)
      done()
    },
    /**
     * @title 数据更新
     * @param row 为当前的数据
     * @param index 为当前更新数据的行数
     * @param done 为表单关闭函数
     *
     **/
    handleUpdate: function (row, index, done, loading) {
      const _this = this;
      putObj(this.filterForm(row)).then(() => {
        this.$message({
          showClose: true,
          message: _this.$t('updateSuccessMsg'),
          type: 'success'
        })
        this.getPage(this.page)
        done()
      }).catch((error) => {
        this.$message({
          showClose: true,
          message: error,
          type: 'error'
        })
      })
    },
    handleDel: function (row) {
      const _this = this;
      this.$confirm(_this.$t('delConfirmMsg'), _this.$t('warnMsg'), {
        confirmButtonText: _this.$t('submitText'),
        cancelButtonText: _this.$t('cancelText'),
        type: 'warning'
      }).then(function () {
        return delObj(row.id)
      }).then(() => {
        this.tableData = []
        this.getPage(this.page)
        _this.$message({
          showClose: true,
          message: _this.$t('delSuccessMsg'),
          type: 'success'
        })
      }).catch(function (error) {
        this.$message({
          showClose: true,
          message: error,
          type: 'error'
        })
      })
    },
    /**
     * @title 数据添加
     * @param row 为当前的数据
     * @param done 为表单关闭函数
     *
     **/
    handleSave: function (row, done, loading) {
      const _this = this;
      addObj(this.form).then(() => {
        this.getPage(this.page)
        done()
        this.$notify({
          title: _this.$t('successMsg'),
          message: _this.$t('addSuccessMsg'),
          type: 'success',
          duration: 2000
        })
      }).catch((error) => {
        this.$message({
          showClose: true,
          message: error,
          type: 'error'
        })
      })
    },
    beforeUpload(file) {
      const _this = this;
      const fileNames = file.name.split(" ")
      if (fileNames.length < 3) {
        this.$message({
          showClose: true,
          message: _this.$t('curd.iptv.video.uploadTipMsg'),
          type: 'error'
        })
        return false
      }
      return true
    },
    successUpload(file, md5) {
      const param = {
        "fileName": file.name,
        "fileSize": file.size,
        "md5": md5
      }
      const form = this.form
      checkFile(param).then(res => {
        const val = res.data.data
        Object.keys(val).forEach(function (key) {
          const value = val[key]
          if (value != null && value !== "") {
            form[key] = value
          }
        })
      })
    },
    errorUpload(error) {
      this.$message({
        showClose: true,
        message: error,
        type: 'error'
      })
    },
    handleClassName({row}) {
      let className = ''
      switch (row.status) {
        case 0: {
          className = 'danger-row'
          break
        }
        case 1: {
          className = 'success-row'
          break
        }
      }
      return className
    }
  }
};
</script>
<style>
.el-table .danger-row {
  background: #ffeeee;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>

