<template>
  <div id="ftp-upload">
    <input type="file" @change="handleFileSelect">
    <div class="progress">
      <el-progress :percentage="percentage" :indeterminate="true" :format="format"
                   v-if="percentage!==0 && percentage!==100 && !exception"/>
      <el-progress :percentage="percentage" v-if="percentage===100 && !exception" status="success"/>
      <el-progress :percentage="percentage" :indeterminate="true" v-if="exception" status="exception"/>
    </div>
  </div>
</template>

<script>
import SparkMD5 from "spark-md5";

export default {
  name: "sftp-upload",
  props: {
    uploadUrl: String,
    checkUrl: String,
    checkMd5: Boolean,
    beforeUpload: Function,
    successCallback: Function,
    errorCallback: Function
  },
  data() {
    return {
      form: {},
      percentage: 0,
      // 将文件按固定大小（2M）进行切片
      chunkSize: 2 * 1024 * 1024,
      format: (percentage) => (percentage === 100 ? 'Full' : `${percentage}%`),
      exception: false,
      md5: ""
    }
  },
  methods: {
    handleFileSelect(event) {
      const file = event.target.files[0];
      // 在这里处理选中的文件
      const success = this.beforeUpload(file)
      if (!success) {
        const fileInput = document.querySelector('input[type="file"]');
        fileInput.value = null;
      } else {
        this.resumeUpload(file)
      }
    },
    async resumeUpload(file) {
      const res = await this.progress(file)
      if (res.data.uploaded === true) {
        let md5 = ""
        if (this.checkMd5) {
          md5 = await this.getFileMD5(file)
        }
        this.percentage = 100
        this.successCallback(file, md5)
        return
      }
      const start = res.data.start
      const arrayBuffers = await this.chunk(start, file)
      const config = {
        headers: {
          'Content-Type': 'application/octet-stream',
          'fileName': file.name
        }
      }
      let count = start
      for (let i = 0; i < arrayBuffers.length; i++) {
        const res = await this.upload(arrayBuffers[i], config)
        if (res.msg === "ok") {
          count += arrayBuffers[i].size
          this.percentage = Number((count * 100 / file.size).toFixed(0))
        } else {
          this.errorCallback(res)
        }
      }
      let md5 = ""
      if (this.checkMd5) {
        md5 = await this.getFileMD5(file)
      }
      this.successCallback(file, md5)
    },
    //分片
    async chunk(start, file) {
      const fileArray = [];
      const fileLen = file.size;
      if (start >= fileLen) {
        return fileArray;
      }
      // 计算总共多个切片
      const chunkSize = this.chunkSize, chunkCount = Math.ceil((fileLen - start) / chunkSize);
      for (let i = 0; i < chunkCount; i++) {
        const end = Math.min(start + chunkSize, fileLen)
        const subFile = file.slice(start, end)
        fileArray.push(subFile)
        start += chunkSize
      }
      return fileArray
    },
    //check
    async progress(file) {
      return await new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('fileName', file.name)
        formData.append('fileSize', file.size)
        axios.post(this.checkUrl, formData).then(res => {
          resolve(res.data)
          this.exception = false
        }).catch(error => {
          this.exception = true
          this.$message({
            showClose: true,
            message: error,
            type: 'error'
          })
        })
      });
    },
    async upload(buffer, config) {
      return await new Promise((resolve, reject) => {
        axios.post(this.uploadUrl, buffer, config).then(res => {
          resolve(res.data)
        }).catch(error => {
          this.$message({
            showClose: true,
            message: error,
            type: 'error'
          })
        })
      });
    },
    async getFileMD5(file) {
      let _this = this
      const loading = this.$loading({
        text: _this.$t('md5LoadingMsg'),
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
        inner: 'el-icon-loading',
      })
      return new Promise((resolve, reject) => {
        const spark = new SparkMD5.ArrayBuffer()
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
          spark.append(e.target.result)
          resolve(spark.end(false))
          loading.close()
        }
        fileReader.onerror = (error) => {
          reject(error)
          this.$message({
            showClose: true,
            message: error,
            type: 'error'
          })
          loading.close()
        }
        fileReader.readAsArrayBuffer(file)
      })
    }
  }
}
</script>

<style scoped>
.progress {
  display: block;
}
</style>